import {required} from 'vuelidate/lib/validators'
import {mapActions, mapGetters, mapMutations} from 'vuex';

export default {
    props: ['popupType', 'selectedItem'],
    data() {
        return {
            form: {
                title: '',
                description: '',
                np_title: 'TurboSMS',
                np_module: 'Turbosms',
                np_description: 'Курьерская служба №1 в Украине',
                np_status: 1,
                turbo_sms_sender: '',
                turbo_sms_api_key: '',
                turbo_secret_key: '',
            },
            list: {
            },
            npAreasList:[],
            npAreasListId:null,
            npCityList:[],
            npCityListId:null,
            npCityWarehouses:[],
            npCityWarehousesId:null,
            contragent: [
                {
                    id:'none',
                    title:'Нету'
                }
            ],
            address:[
                {
                    id:'none',
                    title:'Нету'
                }
            ]
        }
    },

    validations: {
        form: {
            np_title: {
                required
            }
        }
    },
    computed: {
        ...mapGetters({
            options:'config/options',
            npWarehouses: 'plugins/npWarehouses',
            warehouse: 'warehouse/warehouse',
            npAreas: 'plugins/npAreas',
            npCity: 'plugins/npCity',
            counterpartySender: 'plugins/counterpartySender',
            counterpartyAddress: 'plugins/counterpartyAddress',
        }),
    },
    watch:{
        npAreas(e){
            this.npAreasList = [];
            this.npCityList = [];
            this.list.np_area = null;
            this.list.np_city = null;
            this.npCityWarehouses = [];
            this.list.np_warehouse = null;
            let key;
            for(key in e.models){
                this.npAreasList.push(
                    {
                        id:key,
                        title:e.models[key],
                    }
                )
            }
            this.checkOption(this.options);
        },
        npCity(e){
            this.npCityList = [];
            this.list.np_city = null;
            this.npCityWarehouses = [];
            this.list.np_warehouse = null;
            let key;
            for(key in e.models){
                this.npCityList.push(
                    {
                        id:key,
                        title:e.models[key],
                    }
                )
            }
        },
        npWarehouses(e){
            this.npCityWarehouses = [];
            this.list.np_warehouse = null;
            let key;
            for(key in e.models){
                this.npCityWarehouses.push(
                    {
                        id:key,
                        title:e.models[key],
                    }
                )
            }
        },
        options(e){
            this.checkOption(e);
        },
        counterpartySender(e){
            this.contragent = [];
            let key;
            for(key in e.models){
                this.contragent.push({
                    id: key,
                    title: e.models[key],
                })
            }
            this.getCounterpartyAddress({counterparty_id:this.form.np_counterparty_sender});
        },
        counterpartyAddress(e){
            this. address = [];
            let key;
            for(key in e.models){
                this.address.push({
                    id: key,
                    title: e.models[key],
                })
            }
        }
    },
    created() {
        this.getNpAreas();
        this.checkOption(this.option);
        this.getCounterpartySender();
    },
    methods: {
        checkOption(res){
            if(res){
                this.form = res.models;
                this.selectedArea();
                this.selectedCity()
            }else{
                this.form = this.list
            }
        },
        selectedArea(){
            this.getNpCity({'area_id': this.form.np_area})
        },
        selectedCity(){
            this.getNpWarehouses({'city_id': this.form.np_city})
        },
        closePopup() {
            this.$emit('closePopup')
        },
        sendConfirm() {
            if(this.warehouse){
                this.$emit('changeOptions', this.form)
            }else{
                this.$emit('sendOptions', this.form)
            }
        },
        getSenderAddress(){
            this.getCounterpartyAddress({counterparty_id:this.form.np_counterparty_sender});
        },
        ...mapActions({
            getNpAreas:'plugins/getNpAreas',
            getNpCity:'plugins/getNpCity',
            getNpWarehouses:'plugins/getNpWarehouses',
            getCounterpartySender:'plugins/getCounterpartySender',
            getCounterpartyAddress:'plugins/getCounterpartyAddress',
        }),
        ...mapMutations({
            changeShowWarehouse: 'warehouse/changeShowWarehouse'
        })
    },
    destroyed() {
        this.changeShowWarehouse(null);
    }
}